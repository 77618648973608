@import '../../../assets/css/variables.scss';

.contactsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.contact {
    display: flex;
    flex-direction: column;
    padding: 17px 0 17px 0;
    gap: 14px;
    border-radius: 7px;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4
}

.header {
    display: flex;
}

.row {
    display: flex;
    width: 100%;
}

.firstColumn {
    display: flex;
    width: 48px;
    min-width: 48px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 8px;
}

.secondColumn {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
}

.thirdColumn {
    display: flex;
    position: relative;
    width: 65px;
    min-width: 65px;
    justify-content: center;
    align-items: center;
}

.dropdownContainer, .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.dropdown {
    display: flex;
    gap: 8px;
}

.name {
    display: flex;
    gap: 4px;
    font-family: $font-family-lato;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #242222;
}

.companyText {
    font-family: $font-family-lato;
    font-weight: 400;
    font-size: 14px;
    line-height: 22.41px;
    color: #242222;
}

.subtext {
    font-family: $font-family-lato;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.17px;
    color: #242222BF;
}

.text {
    font-family: $font-family-lato;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #242222;
}

.icon {
    cursor: pointer;
}

.rotate {
    rotate: 180deg;
}

.contactMenuContainer {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-14px, 44px);
    z-index: 10;
}

.avatar {
    display: flex;
    width: 72px;
    justify-content: center;
    align-items: flex-start;
}

.avatarImage {
    height: 40px;
    width: 40px;
    border-radius: 40px;
}

.saveBtnWrapper {
    margin: 6px 22px 0 64px;
}

.saveBtn {
    width: 100%;
    border-radius: 46px;
    background-color: #242222 !important;
    height: 48px;
    padding: 12px 24px 12px 24px;
    div {
        color: #FFFFFF
    }
}